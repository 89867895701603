$(document).ready(function() {
  $page = $('.page.page_photo');
  if (!$page.length) return false;
  $page.find('.photo_print .photo_paper .format .wholesale_price button').click(function() {
    var for_beauty = 15;
    var html_scroll = $page.find('.wholesale_prices').offset().top - for_beauty;
    if ($(window).width() <= 1023) {
      html_scroll -= $('header .top_header_container').outerHeight();
      html_scroll -= $('header .banner_container').outerHeight();
    }
    $('html').animate({scrollTop: html_scroll}, 500, 'swing');
  });
  $(document).on('click','.photo_paper .format_list .go_to_editor', function() {
    var $button = $(this);
    $button.prop('disabled', true);
    $.ajax({
      type: 'GET',
      url: $button.data('get-product-json'),
      success: function(json) {
        var button_current_url = $button.data('current-url');
        var post_editor_link = $button.data('post-editor-link');
        json.formats.forEach(function (item) {
          if (item.full_url === button_current_url) {
            json.product = item;
            if (item.adaptive_template_id) {
                post_editor_link += item.id + '/' + item.adaptive_template_id + '/';
            } else {
                post_editor_link += item.id + '/';
            }

          }
        });
        $.ajax({
          type: 'POST',
          url: post_editor_link,
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(json),
          success: function (editor) {
            $.ajax({
              url: $button.data('post-editor-save'),
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify(editor)
            });
            window.location.href = $button.data('redirect-editor') + editor.user_editor_url;
          }
        })
      }
    })
  });
});
