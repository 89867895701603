$(document).ready(function () {
	var page_profile_coupons_selector = '.pages_profile_coupons .coupon .last_date';
	var page_order_complete_selector = '.page_order_complete .coupon .last_date';
	var last_dates = $(page_profile_coupons_selector + ', ' + page_order_complete_selector);
	if (!last_dates.length) {
		return false;
  }

	function msToTime(t) {
		function pad(n) {
			return ('0' + n).slice(-2);
		}
		var seconds = Math.floor((t / 1000) % 60);
		var minutes = Math.floor((t / 1000 / 60) % 60);
		var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
		var days = Math.floor(t / (1000 * 60 * 60 * 24));
		return {
			'days': days,
			'hours': pad(hours),
			'minutes': pad(minutes),
			'seconds': pad(seconds),
			'all': days + ' ' + pad(hours) + ':' + pad(minutes) + ':' + pad(seconds)
		};
	}

	function msToDate(ms) {
		return new Date(ms).toLocaleDateString('ru');
	}

	function timer() {
		last_dates.each(function () {
			var dt = $(this).attr('datetime');
			// Data format changed for MS and Safari
      var dt_split = dt.split('-');
      var dt_new = dt_split[1] + '/' + dt_split[2] + '/' + dt_split[0];
			var lastdate = Date.parse(dt_new + ' 00:00:00');
			var inclusive_day = 24 * 60 * 60 * 1000;
			var timeleft = msToTime(lastdate + inclusive_day - Date.now());

			if (timeleft.days < 0) {
				$(this).closest('.column').remove();
			}
			else if (timeleft.days === 0) {
				var html = timeleft.hours + ':' + timeleft.minutes + ':' + timeleft.seconds;
				$(this).removeClass('date').addClass('timer').html(html);
			}
			else if (timeleft.days < 6 ) {
				if (timeleft.days === 1 ) {
					var dayform = ' день';
				}
				else if (timeleft.days < 5) {
					dayform = ' дня';
				}
				else {
					dayform = ' дней';
				}
				$(this).removeClass('date').addClass('timer').html(timeleft.days + dayform);
			}
			else {
				$(this).addClass('date').html('до ' + msToDate(lastdate));
			}
		});
	}

	timer();
	setInterval(timer, 1000);
});
