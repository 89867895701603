$(document).ready(function() {
  $('.admin_side_panel .toggle').click(function() {
    $('.admin_side_panel .container').addClass('active');
  });
  $('.admin_side_panel .container .head .close').click(function () {
    $('.admin_side_panel .container').removeClass('active');
  });

  fill_admin_panel();
});

function fill_admin_panel() {
  $('.admin_side_panel .body').html($('.admin_data').html());
}