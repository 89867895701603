$(document).ready(function () {
  if ($('.add_review_block').length) {
    $(document).on('click', '.open_review_form', function (e) {
      e.preventDefault();

      var $this = $(this);
      var $target = $this.attr('href');

      $this.addClass('hidden');
      $($target).removeClass('hidden');
      $('.comments_form').removeClass('hidden');
      $('.thanks_review').addClass('hidden');
    });

    $(document).on('click', '.close_form', function () {
      $('.open_review_form').removeClass('hidden');
      $('.comments_form_container').addClass('hidden');
    });

    if (window.location.hash && window.location.hash === '#comments_form_container' && $('#comments_form_container_from').length) {
      $('#tab_3').prop('checked', true);
      if ($('.open_review_form').length) {
        setTimeout(function () {
          $('.open_review_form').first().click();
        })
      }
    }
  }
});