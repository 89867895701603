$(document).on('change', '.page.add-review .file-set .file input', function(e) {
  var $this = $(this);
  var reader = new FileReader();
  var file = this.files[0];
  if (file.type.match('image.*')) {
    reader.onload = function (e) {
      var $file = $this.closest('.file');
      var $clone = $file.clone();
      $file.addClass('loaded');
      $this.after($('<img class="content" src="'+ e.target.result +'"/>'));
      var counter = $this.closest('.file-set').find('.file.loaded').length;
      $this.attr('name', 'product_image_' + counter);
      $clone.find('input').val('');
      $file.after($clone);
    }
  } else {
    reader.onload = function (e) {
      var $file = $this.closest('.file');
      var $clone = $file.clone();
      $file.addClass('loaded');
      $this.after($('<span class="content">'+ file.name +'</span>'));
      var counter = $this.closest('.file-set').find('.file.loaded').length;
      $this.attr('name', 'product_image_' + counter);
      $clone.find('input').val('');
      $file.after($clone);
    }
  }
  reader.onerror = function (e) {
    alert('Ошибка загрузки файла');
  }
  reader.readAsDataURL(this.files[0]);
});
$(document).on('click', '.page.add-review .file-set .file .close', function() {
  $(this).closest('.file').remove();
});
$(document).on('submit', '.page.add-review form', function(e) {
  e.preventDefault();
  var $form = $(this);
  show_loader($form);
  $.ajax({
    url: $form.attr('action'),
    type: $form.attr('method'),
    data: new FormData($form[0]),
    cache: false,
    contentType: false,
    processData: false,
    success: function(res) {
      $('.page.add-review .thanks').removeClass('hidden');
    },
    complete: function() {
      hide_loader($form);
    }
  });
});
