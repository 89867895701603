function Popup () {

	var showPopup = function ($popup_label, overlay_id) {
		var overlay = $('.popup_overlay#' + overlay_id);
		if (overlay.length) {
			$('.popup_overlay').removeClass('active_popup');
			$('.popup_label').removeClass('active');
			$(overlay).addClass('active_popup');
      $popup_label.addClass('active');
		}
	};

	var hidePopup = function () {
		$('.popup_label').removeClass('active');
	};

	var showPresentPopup = function() {
		if ($('.popup_container.present').hasClass('need_open')) {
      showPopup($('.popup_label.present'), 'present');
    }
	};

	var addEvents = function () {
		$(document).ready(function() {
			$('.popup_label').click(function() {
				showPopup($(this), $(this).attr('data-name'));
			});
			$(document).on('click', '.close_popup', hidePopup);
			$('.popup_overlay').click(hidePopup);
			setTimeout(showPresentPopup, 500);
		});
	};

	this.init = function () {
		addEvents();
	};
	this.init();
}

Popup();
