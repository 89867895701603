function create_ajax_form(form_selector, block_selector, func){
  func = func || function(){};
  var form = $('form', form_selector);
  block_selector = block_selector || form_selector;
  if (typeof block_selector === 'string') {
    block_selector = $(block_selector);
  }
  form.submit(function(event){
    event.preventDefault();
    show_loader();
    var $this = $(this);
    var ajax_params = {
      type: $this.attr('method'),
      url: $this.attr('action'),
      success: function(data){
        hide_loader();
        if (block_selector !== 'NotReplace') {
          if (typeof data === 'string') {
            block_selector.html(data);
            metrika_hit();
            create_ajax_form(form_selector, block_selector, func);
            func(block_selector);
          } else if (data.template) {
            block_selector.html(data.template);
            metrika_hit();
            create_ajax_form(form_selector, block_selector, func);
            func(block_selector, data);
          } else {
            create_ajax_form(form_selector, block_selector, func);
            func(block_selector, data);
          }
        }
      }
    };
    if ($this.attr('enctype') === 'multipart/form-data') {
      ajax_params.data = new FormData(this);
      ajax_params.cache = false;
      ajax_params.contentType = false;
      ajax_params.processData = false;
    } else {
      ajax_params.data = $this.serialize();
    }
    $.ajax(ajax_params);
  });
}
