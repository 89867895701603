$(document).ready(function(){
  if ($('.to_cart').length || $('.add-to-cart:not(.photo_editor_to_cart)').length) {
    $(document).on('click', ".to_cart, .add-to-cart:not(.photo_editor_to_cart)", function() {
      if ($(this).hasClass('inactive')) {
        send_metrika_goal('EDITOR_CLICK_INACTIVE_TO_CART');
        return false;
      } else {
        send_metrika_goal('EDITOR_CLICK_ACTIVE_TO_CART');
      }
    });
  }
});