$(document).ready(function() {
  if ($('.catalog_container').length) {
    // СКРИПТ ДЛЯ ФОРМЫ НА СТРАНИЦЕ ОТЗЫВОВ
    $(document).on('click', '.open_reviews:not(.thanks)', function() {
      $(this).addClass('hidden');
      $('.reviews_form_container .review_form_block').slideDown(150);
      $('.reviews_form_container .vacancy_form_block').slideDown(150);
    });
    $(document).on('click', '.close_reviews', function() {
      $('.open_reviews').removeClass('hidden');
      $('.reviews_form_container .review_form_block').slideUp(150);
      $('.reviews_form_container .vacancy_form_block').slideUp(150);
    });

    $(document).on('click', '.show_answer', function() {
      $(this).addClass('hidden');
      $(this).next().removeClass('hidden');
    });

    $(document).on('click', '.hide_answer', function() {
      $(this).parent().addClass('hidden');
      $(this).closest('.answer_container').find('.show_answer').removeClass('hidden');
    });

    // СВЕРТЫВАНИЕ БЛОКОВ НА СТРАНИЦАХ ИНФОРМАЦИИ
    $(document).on('click', '.vacancy_title', function() {
      $(this).toggleClass('active');
      $(this).next('.vacancy_block').slideToggle(200);
      $(this).next('.how_order_block').toggleClass('active');
    });
    $(document).on('click', '.close_block', function() {
      $(this).parent('.vacancy_block').slideUp(200);
      $(this).parent('.how_order_block').removeClass('active');
      $(this).parent('.vacancy_block').prev('.vacancy_title').removeClass('active');
    });
  }
});
