$(document).ready(function() {
  if (!$('.aside').length) return;
  var $last_current = $('.aside a.current').last();
  var $next = $last_current.next();
  if ($next.is('div')) {
    $next.find('a').addClass('tablet_visible');
  } else {
    $last_current.addClass('tablet_visible').siblings().addClass('tablet_visible');
  }
});
