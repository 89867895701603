function is_need_change_photo_params() {
  var $active_tab = $('.page_add_photo .tab.active');
  var $data_tab = $active_tab.attr('data-tab');
  var current_photo_list = $('.photo_list_block[data-tab=' + $data_tab +']');
  var current_params = $('.parameters[data-tab=' + $data_tab +']');
  var current_paper = current_params.find('.paper option:selected');
  var current_margins = current_params.find('.margin option:selected');
  var current_correction = current_params.find('.correction option:selected');
  var current_paper_class = '.paper-' + current_paper.val();
  var current_margins_class = '.margins-' + current_margins.val();
  var current_correction_class = '.correction-' + current_correction.val();
  var class_filter = current_paper_class + current_margins_class + current_correction_class;
  var active_items = current_photo_list.find('.photo_list_item.selected');
  var apply_changes = current_params.find('.apply_changes');
  set_upload_params($active_tab);
  if (active_items.not(class_filter).length) {
      apply_changes.addClass('active');
      return true;
  } else {
      apply_changes.removeClass('active');
      return false;
  }
}

function set_upload_params($active_tab, margin_value) {
  margin_value = margin_value || $active_tab.closest('.page_add_photo').find('.parameters .margin option:selected').val();
  var current_margin = margin_value == 'on' ? 1 : 0;
  $('.add-photo-margin').val(current_margin);
  $('.add-photo-proportion').val($active_tab.attr('data-proportion'));

  photo_scale_editor = $active_tab.attr('data-editor-scale');
  set_dropzone_url();
}

$(document).ready(function() {
  if ($('.page_editor_photo').length || $('.page_photoprint').length) {
    //  ВЫБРАТЬ ВСЕ ФОТО
    $(document).on('click', '.editor_panel .select_all label', function() {
      $(this).toggleClass('active');

      var $data_tab = $(this).closest('.parameters').attr('data-tab');

      var $photo_list = $('.photo_list_block[data-tab=' + $data_tab +'] .photo_list_item:not(.drop_area)');
      if ($(this).hasClass('active')) {
        $photo_list.addClass('active').addClass('selected');
      } else {
        $photo_list.removeClass('active').removeClass('selected');
      }
      is_need_change_photo_params();
    });
    $(document).on('mouseenter', '.page_editor_photo a.to_cart:not(.inactive)', function() {
      $('.status_download_pannel .status_complete.hovered').addClass('active');
    });
    $(document).on('mouseleave', '.page_editor_photo a.to_cart:not(.inactive)', function() {
      $('.status_download_pannel .status_complete.hovered').removeClass('active');
    });
    $(document).on('click', '.page_editor_photo a.to_cart:not(.inactive)', function() {
      $('.status_download_pannel .status_complete').removeClass('hovered').addClass('active');
    });

    //  СКРИПТ ДЛЯ УДАЛЕНИЯ ФОТО НА СТРАНИЦЕ РЕДАКТОРА ФОТОГРАФИЙ
    $(document).on('click', '.photo_list_block .delete_photo', function(e) {
      e.stopPropagation();
      var $parent = $(this).closest('.photo_list_item');
      $parent.addClass('confirm_delete');
    });
    $(document).on('click', '.photo_list_block .confirm-delete .yes', function() {
      var $parent = $(this).closest('.photo_list_item');
      $.ajax({
        url: $parent.attr('data-url'),
        type: 'DELETE',
        data: {},
        beforeSend: function(xhr) {
          xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
        },
        success: function(data) {
          if (data.success) {
            $('.info_and_action .count_number').text(data.new_count);
            $('.info_and_action .price .cost').text(data.new_price);
            if (data.new_price > 0) {
              $('a.to_cart').removeClass('inactive');
            }
            $parent.remove();
          } else {
            alert("Произошла ошибка при удалении изображения.\nОбновите страницу и попробуйте снова.");
          }
        },
        error: function () {
          alert("Произошла ошибка при удалении изображения.\nОбновите страницу и попробуйте снова.");
        }
      });
      return false;
    });
    $(document).on('click', '.photo_list_block .confirm-delete .no', function() {
      var $parent = $(this).closest('.photo_list_item');
      $parent.removeClass('confirm_delete');
      return false;
    });

    // СКРИПТ РЕДАКТИРОВАНИЯ ФОТО НА СТРАНИЦЕ РЕДАКТОРА ФОТОГРАФИЙ
    $(document).on('click', '.photo_list_block .edit_photo', function(e) {
      e.stopPropagation();
    });

    // СКРИПТ ИЗМЕНЕНИЯ КОЛ-ВА ФОТО
    var photoitem_quantity_timeout_id = null;
    $(document).on('keyup mouseup', '.item-quantity', function(){
      var value = $(this).val();
      if (!value || value <= 0) return;
      $('a.to-cart').addClass('inactive');
      var item = $(this).closest('.photo_list_item');
      clearTimeout(photoitem_quantity_timeout_id);
      photoitem_quantity_timeout_id = setTimeout(function() {
        var change_data = {
          'quantity': value,
        };
        $.ajax({
          url: item.attr('data-url'),
          type: 'POST',
          data: change_data,
          beforeSend: function(xhr) {
            xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
          },
          success: function(data) {
            if (data.success) {
              $('.info_and_action .count_number').text(data.new_count);
              $('.info_and_action .price .cost').text(data.new_price);
              if (data.new_price > 0) {
                $('a.to_cart').removeClass('inactive');
              }
            } else {
              alert("Произошла ошибка при изменении количества.\nОбновите страницу и попробуйте снова.");
            }
          },
          error: function (request, status, error) {
            alert("Произошла ошибка при изменении количества.\nОбновите страницу и попробуйте снова.");
          }
        });
      }, 500);
    });
    $(document).on('change', '.item-quantity', function () {
      var value = $(this).val();
      if (!value || value <= 0) {
        $(this).val(1).trigger('mouseup');
      }
    });
    $(document).on('click', '.img_wrapper .icon', function(e) {
      e.stopPropagation();
      var $icon = $(this)
      if ($icon.hasClass('shown')) return;
      $icon.addClass('shown');
      setTimeout(function() {
        $icon.removeClass('shown');
      }, 2500);
    });

    // ДОБАВЛЕНИЕ НОВОЙ ВКЛАДКИ С РАЗМЕРАМИ ПЕЧАТИ
    $(document).on('click', '.page_add_photo .photo_size_cell', function(e) {
      e.preventDefault;
      var $this = $(this);
      var $size = $this.find('.size').text();
      var $tab = $('#template-tab').clone();
      var $parameters = $('#template-parameters').clone();
      var $panel = $('#template-photo-list-block').clone();

      $this.addClass('hidden');

      set_active_tab($this);

      $tab.attr('data-product-pk', $this.attr('data-product-pk'));
      $tab.attr('data-size', $this.attr('data-size'));
      $tab.attr('data-proportion', $this.attr('data-proportion'));
      $tab.attr('data-editor-scale', $this.attr('data-editor-scale'));
      $tab.attr('data-change-format-url', $this.attr('data-change-format-url'));
      $tab.attr('data-editor-format-url', $this.attr('data-editor-format-url'));
      $tab.find('.format_title').text($size)
      $tab.removeAttr('id');
      $tab.removeClass('hidden').addClass('active');
      $tab.appendTo('.page_add_photo .tabs_parameters .tabs');

      $parameters.appendTo('.page_add_photo .all_parameters').removeClass('hidden');
      $parameters.find('select.paper').html($this.find('.paper_template').html());
      $parameters.find('select').removeClass('hidden');
      $parameters.removeAttr('id');

      $panel.appendTo('.page_add_photo .photo_list_pannel').removeClass('hidden');
      $panel.removeAttr('id');

      $('.page_add_photo .tab.plus').appendTo('.page_add_photo .tabs_parameters .tabs');
      $('.page_add_photo .parameters.plus').appendTo('.page_add_photo .all_parameters');
      $('.page_add_photo .photo_list_block.plus').appendTo('.page_add_photo .photo_list_pannel');
      initialize_selects_in_block($parameters);
      if ($('.tabs_parameters .tab').length > 3) {
        $('.tabs_parameters .tab.plus').addClass('mobile_hidden');
      }

      $each();
      $check_capability_delete();
    });

    // ТАБЫ НА СТРАНИЦЕ ЗАГРУЗКИ ФОТО
    function $each() {
      $('.page_add_photo .tab').each(function(i) {
        $(this).attr('data-tab', 'tab_'+i);
      });
      $('.page_add_photo .parameters').each(function(i) {
        $(this).attr('data-tab', 'tab_'+i);
      });
      $('.photo_list_pannel .photo_list_block').each(function(i) {
        $(this).attr('data-tab', 'tab_'+i);
      });
    }
    $each();

    function $check_capability_delete() {
      var $tabs_close = $('.page_add_photo .tab .close');
      if ($tabs_close.length > 1) {
        $tabs_close.removeClass('hidden');
      } else {
        $tabs_close.addClass('hidden');
      }
    }

    if ($('.page_add_photo .tab.active').length) {
      set_upload_params($('.page_add_photo .tab.active'));
    }

    function set_active_tab($element) {
      var $tab = $element.attr('data-tab');
      var $parametrs = $element.closest('.page_add_photo');

      $parametrs.find('.parameters').addClass('hidden');
      $parametrs.find('.photo_list_block').addClass('hidden');
      $parametrs.find('.tab').removeClass('active');
      $parametrs.find('.parameters[data-tab=' + $tab +']').removeClass('hidden');
      $parametrs.find('.photo_list_block[data-tab=' + $tab +']').removeClass('hidden');
      $element.addClass('active');
      set_upload_params($element, $parametrs.find('.parameters .margins option:selected').val());
    }
    $(document).on('click', '.page_add_photo .tab', function() {
      set_active_tab($(this));
      resizable_img();
    });

    if (window.location.hash && window.location.hash !== '#') {
      if ($('.tab[data-tab-title="' + window.location.hash + '"]').length) {
        set_active_tab($('.tab[data-tab-title="' + window.location.hash + '"]'));
      }
    }

    function set_active_tab_in_photo_promo_block($this) {
      var $tab_name = $this.attr('data-tab-name');
      var $tabs = $this.closest('.photoprint_tabs').find('.tabs_photo_size_block');
      $tabs.removeClass('active');
      $tabs.filter('.' + $tab_name).addClass('active');
    }
    if ($('.photoprint_tabs .tab_input:checked').length) {
      $('.photoprint_tabs .tab_input:checked').each(function() {
        set_active_tab_in_photo_promo_block($(this).next());
      });
    }
    $(document).on('click', '.photoprint_tabs .tab_label', function() {
      set_active_tab_in_photo_promo_block($(this));
    });

    // ЗАКРЫТИЕ ВКЛАДКИ
    $(document).on('click', '.tab .close', function() {

      var $parent_tab = $(this).parent('.tab');
      var $parent_tab_attr = $parent_tab.attr('data-tab');
      var $this = $(this);
      var $photo_list_block = $('.photo_list_block[data-tab=' + $parent_tab_attr +']');

      if ($parent_tab.hasClass('active')) {
        $parent_tab.removeClass('active');
        set_active_tab($parent_tab.next('.tab'));
      }

      $('.page_add_photo .tab.plus').removeClass('mobile_hidden');
      if ($photo_list_block.find('.photo_list_item:not(.drop_area)').length) {
        $('a.to_cart').addClass('inactive');
        $.ajax({
          url: $parent_tab.attr('data-change-format-url'),
          type: 'DELETE',
          data: {},
          beforeSend: function(xhr) {
            xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
          },
          success: function(data) {
            if (data.success) {
              $('.info_and_action .count_number').text(data.new_count);
              $('.info_and_action .price .cost').text(data.new_price);
              if (data.new_price > 0) {
                $('a.to_cart').removeClass('inactive');
              }
            } else {
              alert("Произошла ошибка при удалении формата.\nОбновите страницу и попробуйте снова.");
            }
          },
          error: function (request, status, error) {
            alert("Произошла ошибка при удалении формата.\nОбновите страницу и попробуйте снова.");
          }
        });
      }
      $('.photo_size_cell_' + $parent_tab.find('.format_title').text()).removeClass('hidden');
      $parent_tab.remove();
      $('.parameters[data-tab=' + $parent_tab_attr +']').remove();
      $photo_list_block.remove();
      $check_capability_delete();
      return false;
    });
    // ВЫДЕЛЕНИЕ ФОТО В РЕДАКТОРЕ
    $(document).on('click', '.photo_list_block .photo_list_item:not(.drop_area):not(.confirm_delete)', function(e) {
      $(this).toggleClass('selected');
      is_need_change_photo_params();
    });

    $('.parameters select').change(function(){
      var $data_tab = $('.page_add_photo .tab.active').attr('data-tab');
      var selected_photo_items_exist = $('.photo_list_block[data-tab=' + $data_tab +'] .photo_list_item.selected:not(.drop_area)').length;
      if (!selected_photo_items_exist) {
        $(this).closest('.parameters').find('.select_all label').click();
      } else {
        is_need_change_photo_params();
      }
    });

    $(document).on('click', '.apply_changes.active', function(e){
      e.preventDefault;
      var apply_button = $(this);
      apply_button.removeClass('active');
      $('a.to_cart').addClass('inactive');
      var $data_tab = $('.page_add_photo .tab.active').attr('data-tab');
      var current_photo_list = $('.photo_list_block[data-tab=' + $data_tab +']');
      var current_params = $('.parameters[data-tab=' + $data_tab +']');
      var current_paper = current_params.find('.paper option:selected');
      var current_margins = current_params.find('.margin option:selected');
      var current_correction = current_params.find('.correction option:selected');
      var current_paper_class = ' paper-' + current_paper.val();
      var current_margins_class = ' margins-' + current_margins.val();
      var current_correction_class = ' correction-' + current_correction.val();
      var new_classes = 'photo_list_item' + current_paper_class + current_margins_class + current_correction_class;
      var active_items = current_photo_list.find('.photo_list_item.selected');
      active_items.removeClass().addClass(new_classes);
      active_items.find('.paper').text(current_paper.text());
      active_items.find('.margins').text(current_margins.text());
      active_items.find('.correction').text(current_correction.text());
      item_id_list = [];
      active_items.each(function(){
        var item_id = $(this).attr('data-item-id');
        if (item_id) {
          item_id_list.push(item_id);
          $(this).addClass('item-' + item_id);
        }
      });
      if ($('.editor_panel .select_all label').hasClass('active')) {
        $('.editor_panel .select_all label').click();
      }
      var change_data = {
        'product_pk': current_paper.attr('data-product-pk'),
        'margin': current_margins.val(),
        'correction': current_correction.val(),
        'item_id_list': item_id_list,
      };
      $.ajax({
        url: $('.page_add_photo .tab.active').attr('data-change-format-url'),
        type: 'POST',
        data: change_data,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
        },
        success: function(data) {
          if (data.success) {
            $('.info_and_action .price .cost').text(data.new_price);
            $('a.to_cart').removeClass('inactive');
            var random_hash = Math.floor(Math.random() * 6000) + 1;
            active_items.each(function(){
              var img = $(this).find('img');
              img.attr('src', img.attr('src').split('?hash=')[0] + '?hash=' + random_hash);
            });
          } else {
            alert("Произошла ошибка при изменении изображений.\nОбновите страницу и попробуйте снова.");
          }
        },
        error: function (request, status, error) {
          alert("Произошла ошибка при изменении изображений.\nОбновите страницу и попробуйте снова.");
        }
      });
      return false;
    });
  }
});
