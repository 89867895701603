function information_popup_tab($this) {
  $class = $this.attr('data-class');

  $('.information_detail .tab_cell').removeClass('active');
  $('.tab_cell[data-class="' + $class + '"]').addClass('active');

  $('.information_detail .content_block').addClass('hidden');
  $('.information_detail .content_block[data-class="' + $class + '"]').removeClass('hidden');

  if (!$this.hasClass('tab_cell')) {
    send_metrika_goal('OPEN_INFORMATION_POPUP');
  }

  send_metrika_goal('OPEN_INFORMATION_POPUP_' + $class);
}