function openSidebar() {
  $('main').addClass('overlay');
  $('#sidebar-toggle').addClass('active');
  $('#sidebar').addClass('active');
  $("body").css("top", 0).addClass("fixed")
}
function closeSidebar() {
  $('main').removeClass('overlay');
  $('#sidebar-toggle').removeClass('active');
  $('#sidebar').removeClass('active');
  var scroll = $('body').offset().top;
  $('body').css('top', 0).removeClass('fixed');
  $(window).scrollTop(- scroll);
}
function toggleSidebar() {
  if ($('#sidebar').hasClass('active')) {
    closeSidebar();
  } else {
    openSidebar();
  }
}

$(document).ready(function () {
  // ОТКРЫТИЕ И ЗАКРЫТИЕ САЙДБАРА ПРИ КЛИКЕ КНОПКИ МЕНЮ
  $('body').on('click', "#sidebar-toggle", toggleSidebar);
  $('body').on('click', "#sidebar .overlay", closeSidebar);

  // СКРИПТ ДЛЯ ВЫПАДАЮЩЕГО МЕНЮ САЙДБАРА
  function $toggle() {
    $(this).parent().toggleClass('active');
  }
  $('.nav_sidebar_container .nav_menu_link').removeAttr('href');
  $(document).on('click', '.nav_sidebar_container .choice h2', $toggle);
  $(document).on('click', '.nav_sidebar_container .nav_menu_link', $toggle);
  $(document).on('click', ".nav_sidebar_container .choice .sub_menu_cell h3", $toggle);
  $(document).on('click', ".sidebar_faq .open", function () {
    $(this).parent().toggleClass('active');
  });
  $(document).on('click', ".sidebar_about .open", function () {
    $(this).parent().toggleClass('active');
  });

  // ЗАКРЫТИЕ САЙДБАРА ПРИ СВАЙПЕ ВЛЕВО
  $('#sidebar .content').swipe({ swipeLeft: closeSidebar });
});
