$(document).ready(function() {
  if (!$('aside.menu').length) return;

  function asideMenuFixed() {
    if (window.innerWidth <= 1023) return false;
    var $aside = $('aside.menu');
    var $footer = $('footer');
    if (window.pageYOffset > $aside.parent().offset().top) {
      $aside.addClass('fixed');
      var aside_place = $footer.offset().top - window.pageYOffset - $aside.outerHeight();
      if (aside_place < 0) {
        $aside.css('top', aside_place);
      } else {
        $aside.prop('style', null);
      }
    } else {
      $aside.removeClass('fixed');
    }
  }

  $(window).on('scroll', asideMenuFixed);
  $(window).on('resize', asideMenuFixed)
  $('aside.menu .toggle, aside.menu .close').click(function() {
    $(this).closest('aside.menu').toggleClass('shown');
  });
});