if ($ && $.pjax && $.pjax.defaults) {
  $.pjax.defaults.timeout = 3000;
  $.pjax.defaults.scrollTo = false;
}

$(document).ready(function() {
  $(document).pjax('a.pjax_update:not(.pjax_detail)', '#pjax_container');
  $(document).pjax('a.pjax_detail', '#pjax_container_detail');
  $(document).pjax('.tab_content_cell', '#main_info_from');
  $(document).on('pjax:complete', function() {
    initialize_selects();
    initialize_sliders();
    page_product_list.init_catalog_cells();
    $(".comment_hidden_data").html($(".comment_hidden_new_data").html());
    $(".tabs_text_block.description_text").html($(".new_description_text").html());
    if ($('#pjax_container_detail').hasClass('not_available')) {
      $('#pjax_container_detail').removeClass('not_available');
      $('#pjax_container_detail').parent().removeClass('not_available');
    }
    $("img.lazy").lazyload({placeholder: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD//gATQ3JlYXRlZCB3aXRoIEdJTVD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAUYf/8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPwF//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPwF//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPyF//9oADAMBAAIAAwAAABAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPxB//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPxB//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPxB//9k='});
    page_product_list.jscroll();

    if ($('.total_promoblock').length) {
      create_ajax_form('.total_promoblock', null, function(block_selector, data) {
        var error_list = $('.total_promoblock .errorlist');
        if (data.errors) {
          error_list.removeClass("hidden");
          error_list.html("<li>" + data.errors + "</li>")
        } else if (data.success_url) {
          error_list.addClass("hidden");
          show_loader();
          document.location.href = data.success_url
        }
      });
    }

    if ($('.comments_form_container').length) {
      create_ajax_form('.comments_form_container');
    }

    if ($('select.standart').length) {
      set_standart_select_width();
    }

    metrika_hit();

    fill_admin_panel();

  });
  $(document).on('submit', 'form.pjax_form', function(event) {
    $.pjax.submit(event, '#pjax_container');
  });
});
