$(document).ready(function() {
  if ($('.page_profile_history_container').length) {
    // РАСКРЫТИЕ БЛОКА НА СТРАНИЕЦЕ ИСТОРИИ ЗАКАЗОВ
    $(document).on('click', '.history_container .header_history', function(e) {
      $(this).parent().toggleClass('active');
      $(this).next('.main_history').slideToggle();
      $(this).parent().find('.close_block').addClass('active');
    });
    $(document).on('click', '.history_container .close_block', function() {
      var $parent = $(this).closest('.history_container');
      $parent.removeClass('active');
      $parent.find('.main_history').slideUp();
    });
  }
});