$(document).ready(function () {
  if ($('.order.labs').length) {
    var refresh_timeout = null;
    $(document).on('change', '.order-status-select', function () {
      if (refresh_timeout) {
        clearTimeout(refresh_timeout);
      }
      refresh_timeout = setTimeout(function () {
        window.location.reload();
      }, 60000);
    })
  }
});