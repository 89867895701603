$(document).ready(function() {
  if ($('.page_promo').length) {
    function page_promo_tabs() {
      if (window.innerWidth < 1280) {
        $('.info_main .page_promo_tabs_container').appendTo('.main_information_container');
      } else {
        $('.main_information_container .page_promo_tabs_container').appendTo('.info_main');
      }
    }

    page_promo_tabs();
    $(window).resize(function () {
      page_promo_tabs();
    });

    $(document).on('click', '.more_items_block .more_link', function () {
      if ($(this).hasClass('active')) {
        $(this).closest('.tab_content_block').removeClass('show');
        $(this).text('Показать все').removeClass('active');
      } else {
        $(this).closest('.tab_content_block').addClass('show');
        $(this).text('Скрыть').addClass('active');
      }
    });


    $(document).on('click', '.page_promo .tab', function () {
      var $thisAttr = $(this).attr('data-tab'),
        $parent = $(this).closest('.page_promo');

      $parent.find('.tab').removeClass('active');
      $(this).addClass('active');

      $parent.find('.tab_content_block').addClass('hidden');
      var $active_tab = $parent.find('.tab_content_block[data-tab=' + $thisAttr + ']');
      $active_tab.removeClass('hidden');
      $active_tab.children().first().click();

    });
  }
});
