// popup
$(document).on('click', '.popup_trigger', function(e) {
  var content_name = $(this).data('popup');
  if (!content_name) return;
  $('#popup .content_popup').addClass('hidden');
  $('#popup .content_popup.' + content_name).removeClass('hidden');
  $('#popup').removeClass('hidden');
  //location callback (focus works only with event context)
  if (content_name === 'location') {
    $(this).closest('body').find('#popup .content_popup.location .input').focus();
  }
  if (content_name === 'add_review') {
    $(this).closest('body').find('#popup .content_popup.add_review .text').focus();
  }
});
$(document).on('click', '#popup', function(e) {
  if ($(e.target).attr('id') === 'popup' || $(e.target).hasClass('close')) {
    $('#popup').addClass('hidden');
  }
});

// location events
$(document).ready(function() {
  var timer;
  $(document).on('keyup', '#popup .content_popup.location .input', function() {
  clearInterval(timer);
  var query = $(this).val();
  timer = setTimeout(function () {
    if (query) {
      $.ajax({
        type: 'GET',
        url: '/geolocation/city/?q=' + query,
        success: function (res) {
          $('#popup .content_popup.location .city_list .city.default').addClass('hidden');
          $('#popup .content_popup.location .city_list .city:not(.default)').remove();
          res.response.items.forEach(function(item) {
            text = item.title;
            item.region ? text += ', ' + item.region : '';
            item.area ? text += ', ' + item.area : '';
            $button = $('<button class="city" type="button">');
            $button.attr('data-id', item.id).text(text);
            $('#popup .content_popup.location .city_list').append($button);
          });
        }
      });
    } else {
      $('#popup .content_popup.location .city_list .city:not(.default)').remove();
      $('#popup .content_popup.location .city_list .city.default').removeClass('hidden');
    }
  }, 300);
});
  $(document).on('click', '#popup .content_popup.location .city_list .city', function(e) {
    $('#popup .content_popup.location .city_form input').val($(this).data('id')).parent().submit();
  });
});
// add_review events
$(document).ready(function() {
  $(document).on("change", '#popup .content_popup.add_review .photo input', function () {
    var $this = $(this);
    var $preview = $this.parent().find('.preview');
    var $file_wrapper = $this.parent();
    if (this.files && this.files[0]) {
      if (this.files[0].type.match('image.*')) {
        var reader = new FileReader();
        reader.readAsDataURL(this.files[0]);
        reader.onload = function (e) {
          $file_wrapper.addClass('loaded');
          $preview.attr('src', e.target.result);
        }
      }
    }
  });
  $(document).on('submit', '#popup .content_popup.add_review', function(e) {
    e.preventDefault();
    var $form = $(this);
    $form.find('.add_review_data').remove();
    $('.page.product .reviews_product .add_review_data').clone().appendTo($form);
    show_loader($form);
    $.ajax({
      url: $form.attr('action'),
      type: $form.attr('method'),
      data: new FormData($form[0]),
      success: function(res) {
        $form[0].reset();
        $form.find('.thanks').removeClass('hidden');
      },
      complete: function() {
        hide_loader($form);
      },
      cache: false,
      contentType: false,
      processData: false
    });
  });
});