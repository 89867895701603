$(document).ready(function() {
  if ($('.comments_form').length) {
    $(document).on('mouseover', '.comments_form .rate_star .star', function() {
      var $this = $(this);
      $this.addClass('fill');
      $this.prevAll('.star').addClass('fill');
      $this.nextAll('.star').removeClass('fill');
    });

    $(document).on('mouseleave', '.comments_form .rate_star .star', function() {
      var $this = $(this);
      var $current_star = $this.closest('.rate_star').find('input[checked="checked"]');
      var $attr = $current_star.attr('id');
      var $current_label = $this.closest('.rate_star').find('.star[for="' + $attr + '"]');

      if ($this.closest('.rate_star').find('input[checked="checked"]').length) {
        $current_label.addClass('fill');
        $current_label.prevAll('.star').addClass('fill');
        $current_label.nextAll('.star').removeClass('fill');
      } else {
        $this.closest('.rate_star').find('.star').removeClass('fill')
      }
    });

    $(document).on('click', '.comments_form .rate_star .star', function() {
      var $this = $(this);
      $this.addClass('fill');
      $this.prevAll('.star').addClass('fill');
      $this.nextAll('.star').removeClass('fill');

      $this.parent().find('input').removeAttr('checked');
      $this.parent().find('#'+ $this.attr('for')).attr('checked','checked');
      $this.parent().find('#'+ $this.attr('for')).trigger('change');
    })
  }
});
